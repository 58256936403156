import styled from 'styled-components';
import Background from '../../images/bg.jpg';

export const TitleContainer = styled.div`
  background: #fff url(${Background}) repeat-x top center;
  height: 670px;
  @media (max-width: 870px) {
    height: auto;
    padding-top: 20px;
  }
`;

export const TitleStyle = styled.div`
  width: 800px;
  height: auto;
  margin: 0 auto;
  padding: 0px 0px 0px 0px;
  text-align: center;
  @media (max-width: 870px) {
    width: 100%;
    height: auto;
    padding: 0 35px 35px 35px;
    text-align: center;
  }
`;

export const HeaderH2 = styled.h2`
  margin-bottom: 30px !important;
  margin-top: 40px !important;
  color: #fff;
  margin: 0 0 15px 0;
  padding: 0;
  font-family: 'MrAlex', arial, sans-serif;
  font-weight: normal !important;
  font-size: 25px;
  line-height: 35px;
  text-align: center;
  code {
    color: #ffca05;
    font-family: 'MrAlex', arial, sans-serif;
    font-weight: normal !important;
    font-size: 25px;
    line-height: 35px;
  }
  @media (max-width: 870px) {
    font-size: 23px;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const ImageStyle = styled.img`
  border: 0;
  vertical-align: top;
  width: 500px;
  height: auto;
  @media (max-width: 870px) {
    max-width: 100%;
    width: 300px;
    height: auto;
    margin-top: 0px;
    margin-bottom: 30px;
  }
`;
